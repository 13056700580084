import React from 'react';

var primaryColor;
var secondaryColor;
var buttonTextColor;
const DefaultButton = ({ onClick, label, icon, showImage, isLoading }) => {
  primaryColor = sessionStorage.getItem('primaryColor');
  secondaryColor = sessionStorage.getItem('secondaryColor');
  buttonTextColor = sessionStorage.getItem('buttonTextColor')

  const buttonStyles = {
    backgroundColor: primaryColor,
    transition: 'all 0.3s ease',
    color: `${buttonTextColor}`
  };

  return (
    <div className='defaultBtn'>
      <button
        onClick={onClick}
        className='flex items-center rounded-2xl h-[56px] sm:h-[64px] w-[250px] sm:w-auto text-[15px] sm:text-2xl uppercase font-bold justify-center px-[40px] transition-all'
        style={buttonStyles}
        disabled={isLoading}
      >
        <div className='flex justify-center items-center'>
          {showImage && <img src={icon} alt="Icon" className='mr-5 w-[32px]' />}
          <span className='inline-block mr-2 uppercase'>{label}</span>
          {isLoading && <div id="loading-bar-spinner" className="spinner"><div className="spinner-icon"></div></div>} 
        </div>
      </button>
    </div>
  );
};

export default DefaultButton;
