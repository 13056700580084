export const getHeaders = () => {
  const webtoken = sessionStorage.getItem('uuid')
  const deviceType = sessionStorage.getItem('deviceSize') || 'mobile';
  const token = sessionStorage.getItem('token');
  const selLang = sessionStorage.getItem('selectedLanguage');
  const headers = {
    'Content-Type': 'application/json',
    'WEB-TOKEN': webtoken,
    'DEVICE-SIZE': deviceType,
  };

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }
  if (selLang) {
    headers['locale'] = `${selLang}`;
  }

  return headers;
};
