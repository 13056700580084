import React from 'react'

const KioskDevice = () => {
  return (
    <>
        <div className='z-1 overlay absolute w-full h-full'></div>
        <div className='absolute left-[50%] top-[50%] bg-[#fff]  flex items-center flex-col justify-center w-full h-full m-auto p-[20px] sm:p-[50px] rounded-none prompt text-center z-2'>
            <h3 className='text-center text-OyesterGrey font-bold text-[30px]'>Service Unavailable</h3>
            <p className='text-gray-600 text-[20px] mt-3 sm:w-[400px] w-auto'>The kiosk you are tyring to access is currently not linked. Please ensure the kiosk is linked or contact support for assistance.</p>
        </div>
    </>
  )
}

export default KioskDevice
