import React, { useEffect, useState } from 'react';
import Header from '../Components/Headers/Header'
import { useNavigate } from 'react-router-dom';
import ApiCallingToaster from '../Components/Toaster/ApiCallingToaster';
import DynamicApiCallingToaster from '../Components/Toaster/DynamicApiCallingToaster';
import { getHeaders } from '../utils/apiUtils';
import DefaultButton from '../Components/Button/DefaultButton';
// import { useTranslation } from 'react-i18next';
// import { Helmet } from 'react-helmet';
import { updateFavicon } from '../utils/favicon';

// Utility function to convert HTML to text
var privacyDate;
var noAgent;
var primaryColor  
var secondaryColor
var headerColor   


var firstStar;
var secondStar;
var thirdStar;
var fourthStar;
var fifthStar;

var home_Bg1
var home_Bg2
var buttonTextColor


const Terms = () => {
  const company_name = sessionStorage.getItem('company_name')
  const currentUrl = sessionStorage.getItem('currentUrl')

  const navigate = useNavigate();
  const [defaultApiError, setDefaultApiError] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState('');
  const [privacyContent, setPrivacyContent] = useState('');
  const [privacyDateShow, setPrivacyDateShow] = useState('')
  const [onlineStatus, setOnlineStatus] = useState(navigator.onLine); 
  const [onlineStatusShow, setOnlineStatusShow] = useState(false); 
  const [isLoading, setIsLoading] = useState(false);
  const [favicon, setFavicon] = useState(null);
  const [fontFamily, setFontFamily] = useState('');
  useEffect(() => {
    if (favicon) {
      const link = document.createElement('link');
      link.rel = 'icon';
      link.href = favicon;
      document.head.appendChild(link);

      return () => {
        document.head.removeChild(link);
      };
    }
  }, [favicon]);
  useEffect(() => {
    const captchaVerified = sessionStorage.getItem('capatchaVerified');

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });
    };

    if (captchaVerified == null) {
      window.location.replace('/');
    } else {
      const privacyHtml = sessionStorage.getItem('privacyPage');
      privacyDate = sessionStorage.getItem('privacyDate');

      if (privacyHtml) {
        setPrivacyContent(privacyHtml);
      }
      if (privacyDate) {
        const formattedDate = formatDate(privacyDate);
        setPrivacyDateShow(formattedDate);
      }
    }
  }, []);
  const loadFont = (fontFamily) => {
    const fontFaces = {
      'NotoSans-Medium': 'NotoSans-Medium',  
      'Lato-Regular':    'Lato-Regular',
      'Raleway-Medium':  'Raleway-Medium',
      'Poppins-Medium':  'Poppins-Medium',
      'Inter-Medium':   'Inter-Medium',
      'OpenSans-Medium': 'OpenSans-Medium',
      'Times New Roman': 'Times New Roman',
      'Merriweather-Regular': 'Merriweather-Regular',
      'Oswald-Medium': 'Oswald-Medium',
      'Rubik-Medium': 'Rubik-Medium'
  };
    if (fontFaces[fontFamily]) {
      const fontFace = new FontFace(fontFaces[fontFamily], `url(${currentUrl}src/fonts/${fontFaces[fontFamily]}.woff2)`);
        console.log('fontFace', fontFace)
        fontFace.load().then((loadedFace) => {
            document.fonts.add(loadedFace);
            document.body.style.fontFamily = fontFaces[fontFamily];
        }).catch((error) => {
            console.error(`Failed to load font: ${fontFamily}`, error);
        });
    }
};
  const fetchGeneralSetting = async () => {
    try {
      setDefaultApiError(false);
      const settingUrl = `${process.env.REACT_APP_BASE_URL_API}general-setting`;
      const settingResponse = await fetch(settingUrl, {
        method: 'GET',
        headers: getHeaders(),
      });

      if (!settingResponse.ok) {
        const errorData = await settingResponse.json();
        let errorMessage = errorData.error.message;
        setApiError(true);
        setApiErrorMsg(errorMessage);
        return;
      }

      const settingData = await settingResponse.json();
      const settingImg = settingData.data.company.queue_waiting_image;
      const settingLogo = settingData.data.company.logo;
      const company_name = settingData.data.company.company_name;
      const noAgent = settingData.data.company.no_agent;
      const mmUrl = settingData.data.company.maintenanceModeImage;
      const mmStatus = settingData.data.company.maintenance_mode;

      const splashImg = settingData.data.company.kiosk_device.screensaver;
      const kioskId = settingData.data.company.kiosk_device.kiosk_id;

      const showPopupTime = settingData.data.inactivity_popup_show_time;
      const dismissPopupTime = settingData.data.inactivity_popup_dismiss_time;
      const privacyPage = settingData.data.company.privacy_page
      
      const privacy_updated_at = settingData.data.company.privacy_updated_at
      const fetchedFontFamily = settingData.data.company.theme_customization.font; // Fetch font family
     
      primaryColor = settingData.data.company.theme_customization.primary_color; // Fetch font family
      secondaryColor = settingData.data.company.theme_customization.secondary_color; // Fetch font family
      headerColor  = settingData.data.company.theme_customization.header_color; 

      firstStar = settingData.data.company.one_star_rating;       
      secondStar  = settingData.data.company.two_star_rating;
      thirdStar  = settingData.data.company.three_star_rating;
      fourthStar  = settingData.data.company.four_star_rating;
      fifthStar  = settingData.data.company.five_star_rating;
      home_Bg1 = settingData.data.company.theme_customization.home_background_color_1;
      home_Bg2 = settingData.data.company.theme_customization.home_background_color_2;
      buttonTextColor = settingData.data.company.theme_customization.button_text

      updateFavicon(settingData.data.company.favicon);
      const textColorVal = settingData.data.company.is_voncierge
      sessionStorage.setItem('privacyDate', privacy_updated_at)
      sessionStorage.setItem('privacyPage', privacyPage)
      sessionStorage.setItem('settingImg', settingImg);
      sessionStorage.setItem('settingLogo', settingLogo);
      sessionStorage.setItem('inactivity_popup_show_time', showPopupTime);
      sessionStorage.setItem('inactivity_popup_dismiss_time', dismissPopupTime);
      sessionStorage.setItem('company_name', company_name);
      sessionStorage.setItem('no_agent', noAgent);
      sessionStorage.setItem('mmUrl', mmUrl);
      sessionStorage.setItem('mmStatus', mmStatus);
      sessionStorage.setItem('splashImg', splashImg);
      sessionStorage.setItem('kioskId', kioskId);
      sessionStorage.setItem('fetchedFontFamily', fetchedFontFamily)
      sessionStorage.setItem('headerColor', headerColor)
      sessionStorage.setItem('primaryColor', primaryColor)
      sessionStorage.setItem('secondaryColor', secondaryColor)

      sessionStorage.setItem('textColorVal', textColorVal)

      sessionStorage.setItem('firstStar', firstStar)
      sessionStorage.setItem('secondStar', secondStar)
      sessionStorage.setItem('thirdStar', thirdStar)
      sessionStorage.setItem('fourthStar', fourthStar)
      sessionStorage.setItem('fifthStar', fifthStar)

      sessionStorage.setItem('home_Bg1', home_Bg1)
      sessionStorage.setItem('home_Bg2', home_Bg2)
      sessionStorage.setItem('buttonTextColor', buttonTextColor)

      setFontFamily(fetchedFontFamily);
      loadFont(fetchedFontFamily);

      document.body.style.fontFamily = fetchedFontFamily;
      const companyClass = sessionStorage.getItem('textColorVal');
      if (companyClass == 0) {
        document.body.classList.add('changi');
        document.body.classList.remove('voncierge');
      } else {
        document.body.classList.add('voncierge');
        document.body.classList.remove('changi');
      }
      if (mmStatus == 1) {
        navigate('/maintenance');
      }
    } catch (error) {
      setDefaultApiError(true);
    }
  };
  useEffect(() => {
    fetchGeneralSetting();
  }, [])
  const onHandleClick = () => {
    
    navigate('/languages');
    // const fetchNoAgent = async () => {
    //   setIsLoading(true)
    //   const noAgentImg = sessionStorage.getItem('no_agent');
    //   try {
    //     const settingResponse = await fetch(noAgentImg);
    //     if (!settingResponse.ok) {
    //       fetchGeneralSetting();
    //     }
    //     navigate('/languages');
    //   } catch (error) {
    //     setDefaultApiError(true);
    //     setIsLoading(false)
    //   }
    //   finally{
    //     setIsLoading(false);
    //   }
    // };
    // fetchNoAgent();
  };
  useEffect(() => {
    sessionStorage.removeItem('isConnected')
    noAgent = sessionStorage.getItem('no_agent');
    const handleOnlineStatusChange = () => {
      setOnlineStatus(navigator.onLine);
    };
    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);
    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, []);
  // const { t } = useTranslation();
  useEffect(() => {
    if (!onlineStatus) {
      setOnlineStatusShow(true)
    }
    else {
      setOnlineStatusShow(false)
    }
  }, [onlineStatus]);
  useEffect(() => {
    if (company_name) {
      const cleanedCompanyName = company_name.replace(/\s+/g, '');
      document.body.classList.add(cleanedCompanyName);
    }
  }, [company_name])

  useEffect(() => {
    function handleVisibilityChange() {
      if (isMobileOrTablet()) {
        if (document.visibilityState === 'visible' || document.visibilityState === 'hidden' || document.beforeunload === 'visible' || document.beforeunload === 'hidden' || document.unload === 'visible' || document.unload === 'hidden') {
          window.location.replace('/');
        }
      }
    }
    function isMobileOrTablet() {
      const userAgent = navigator.userAgent.toLowerCase();
      return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    }
    document.addEventListener("visibilitychange", handleVisibilityChange);
    document.addEventListener("beforeunload", handleVisibilityChange);
    document.addEventListener("unload", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      document.removeEventListener("beforeunload", handleVisibilityChange);
      document.removeEventListener("unload", handleVisibilityChange);
    };
  }, []);
  const goHome = () => {
    navigate('/')
  }
  return (
    <>
      {/* <Helmet>
        <title>{company_name ? `${company_name} | Terms` : 'Voncierge | Terms'}</title>
      </Helmet> */}
      <div className='customTransition termsPage'>
        <Header showHomeButton={true} handleGoHome={goHome} />
        <div className='py-[51px] bg-cover bg-no-repeat termsBgImg firstScreen' style={{backgroundImage : `linear-gradient(90deg, ${home_Bg1} 0%, ${home_Bg2} 100%)`}}>
          {/* <h1 className='text-white text-center text-[20px] sm:text-[48px] leading-[normal] font-bold'>{t('headings.termsHeading')}</h1>
          <h6 className='text-white text-center text-[14px] sm:text-[16px]'>{t('headings.lastUpdated')}: {privacyDateShow}</h6> */}
          <h1 className='text-white text-center text-[20px] sm:text-[48px] leading-[normal] font-bold'>Terms of use</h1>
          <h6 className='text-white text-center text-[14px] sm:text-[16px]'>Last Updated: {privacyDateShow}</h6>
        </div>
        <div className='lg:max-w-[794px] lg:m-auto mx-[30px] pt-[40px] sm:pb-0 px-4 sm:px-0 termsDescription'>
          <pre className='text-[#262626] text-[16px] sm:text-[20px] mt-4 whitespace-normal leading-7' dangerouslySetInnerHTML={{ __html: privacyContent }} style={{ '--custom-font-family': fontFamily }}></pre>
        </div>
        <div className={onlineStatusShow ? "fixed bottom-0 btnDiv w-full z-50 h-[97px] flex justify-center items-center bg-white customBtn pointer-events-none" : "fixed bottom-0 btnDiv w-full z-50 h-[97px] flex justify-center items-center bg-white customBtn"}>
          {/* <DefaultButton label={t('headings.accept&continue')} onClick={onHandleClick} isLoading={isLoading} /> */}
          <DefaultButton label='Accept and Continue' onClick={onHandleClick} isLoading={isLoading} />

        </div>
      </div>
      {defaultApiError && <ApiCallingToaster />}
      {apiError && <DynamicApiCallingToaster content={apiErrorMsg} />}
        <div className={onlineStatusShow ? "block fixed bg-themeColor text-white text-center py-2 bottom-0 w-full" : "hidden"}>
          We regret to inform you that there is no internet connection available at the moment. We apologise for any inconvenience this may cause.
        </div>
    </>
  );
};

export default Terms;
