import React, { useState, useEffect } from 'react';
import Header from '../Components/Headers/Header';
import Modal from '../Components/Modal';
import { useNavigate } from 'react-router-dom';
// import { useTranslation } from 'react-i18next'; 
import Loader from '../images/loader.gif';
import ApiCallingToaster from '../Components/Toaster/ApiCallingToaster';
import DynamicApiCallingToaster from '../Components/Toaster/DynamicApiCallingToaster';
import { getHeaders } from '../utils/apiUtils';
// import PusherFunction from '../Components/PusherSocket';
// import { Helmet } from 'react-helmet';

var noAgent;

const SelectLanguage = () => {
  const fontFamily  = sessionStorage.getItem('fetchedFontFamily')
  
  // console.log('fontFamily', fontFamily)
  const company_name = sessionStorage.getItem('company_name')
  const primaryColor = sessionStorage.getItem('primaryColor')
  sessionStorage.getItem('textColorVal')
  const [apiError, setApiError] = useState(false);
  const [apiStatusError, setApiStatusError] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState(false);
  const [hideCategoryDiv, setHideCategoryDiv] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);
  const [onlineStatus, setOnlineStatus] = useState(navigator.onLine); 
  const [onlineStatusShow, setOnlineStatusShow] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [companyLanguages, setCompanyLanguages] = useState([]);
  const [showBottomArrow, setShowBottomArrow] = useState(false); // New state for bottom arrow

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); 
  useEffect(() => {
    if (company_name) {
      const cleanedCompanyName = company_name.replace(/\s+/g, '');
      document.body.classList.add(cleanedCompanyName);
    }
  }, [company_name])
  useEffect(() => {
    sessionStorage.removeItem('isConnected');
    noAgent = sessionStorage.getItem('no_agent');
    const handleOnlineStatusChange = () => {
      setOnlineStatus(navigator.onLine);
    };
    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);
    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, []);

  useEffect(() => {
    setOnlineStatusShow(!onlineStatus);
  }, [onlineStatus]);

  useEffect(() => {
    const status = sessionStorage.getItem('mmStatus');
    if(status === '1') {
      navigate('/maintenance');
    }
  }, [navigate]);

  const fetchCompanyLanguages = async () => {
    try {
      setHideCategoryDiv(false);
      setLoading(true); // Start loading
      const url = `${process.env.REACT_APP_BASE_URL_API}company-languages?limit=200&page=1`;
      const response = await fetch(url, {
        method: 'GET',
        headers: getHeaders(),
      });
      if (!response.ok) {
        const errorData = await response.json(); 
        let errorMessage = errorData.error.message;
        setApiStatusError(true);
        setHideCategoryDiv(true); 
        if (response.status === 420) {
          setApiErrorMsg(errorMessage);
          return;
        } else {
          setApiErrorMsg(errorMessage);
          setTimeout(() => {
            window.location.replace('/');
          }, 1000);
          return;
        }
      }

      const data = await response.json();
      if (Array.isArray(data.data)) {
        setCompanyLanguages((prevLanguages) => [...prevLanguages, ...data.data]);
      } 
    } catch (error) {
      setApiError(true);
    } finally {
      setLoading(false);
      checkBottomArrow(); // Check for bottom arrow when data is loaded
    }
  };

  // const { t, i18n } = useTranslation();
  // useEffect(() => {
  //   i18n.changeLanguage('defaultLanguage');
  // }, [i18n]);

  const [activeId, setActiveId] = useState(() => {
    return sessionStorage.getItem('selectedLanguage') || 0;
  });

  const handleCheckboxClick = async (id) => {
    try {
      if (!onlineStatus) {
        return;
      }
      setApiError(false);
      const url = `${process.env.REACT_APP_BASE_URL_API}login-register-kiosk`;
      const response = await fetch(url, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({ selectedLanguage: id }),
      });

      if (!response.ok) {
        const errorData = await response.json(); 
        let errorMessage = errorData.error.message;
        setApiStatusError(true);
        setHideCategoryDiv(true); 
        if (response.status === 420) {
          setApiErrorMsg(errorMessage);
          return;
        } else {
          setApiErrorMsg(errorMessage);
          setTimeout(() => {
            window.location.replace('/');
          }, 1000);
          return;
        }
      }

      const responseData = await response.json();
      sessionStorage.setItem('token', responseData.data.token);
      sessionStorage.setItem('user_id', responseData.data.kioskuser.user_id);
      setActiveId(id);
      sessionStorage.setItem('selectedLanguage', id);
      // i18n.changeLanguage(id); 
      navigate(`/${id}/categories`); 
    } catch (error) {
      setApiError(true);
    } finally {
      setLoading(false);
    }
  };

  const HeaderGoHome = () => {
    setTimeout(() => {
      window.location.replace('/');
    }, 50);
  };

  const hideInactivePopup = parseInt(sessionStorage.getItem('inactivity_popup_dismiss_time')) * 1000;
  const [countdown, setCountdown] = useState(hideInactivePopup / 1000);
    
  const closeModal = (redirect) => {
    setModalOpen(false);
    if (redirect) {
      window.location.replace('/');
    } else {
      window.location.reload();
      setCountdown(0);
    }
  };

  useEffect(() => {
    let timer;
    if (modalOpen && countdown > 0) {
      timer = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [modalOpen, countdown]);

  useEffect(() => {
    if (countdown === 0) {
      closeModal();
    }
  }, [countdown]);

  useEffect(() => {
    const showInactivePopup = parseInt(sessionStorage.getItem('inactivity_popup_show_time')) * 1000;
    let inactivityTimer;
    let closeTimer;

    const resetTimers = () => {
      clearTimeout(inactivityTimer);
      clearTimeout(closeTimer);
      inactivityTimer = setTimeout(() => {
        setModalOpen(true);
        closeTimer = setTimeout(() => {
          setModalOpen(false);
          setTimeout(() => {
            navigate('/');
          }, 500);
        }, hideInactivePopup);
      }, showInactivePopup);
    };

    const clearTimers = () => {
      clearTimeout(inactivityTimer);
      clearTimeout(closeTimer);
    };

    const handleActivity = () => {
      resetTimers();
    };

    // Initial setup
    resetTimers();

    // Event listeners
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);
    window.addEventListener('click', handleActivity);
    window.addEventListener('scroll', handleActivity);
    window.addEventListener('touchstart', handleActivity);
    // Clean-up
    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
      window.removeEventListener('click', handleActivity);
      window.removeEventListener('scroll', handleActivity);
      window.removeEventListener('touchstart', handleActivity);
      clearTimers();
    };
  }, [hideInactivePopup, navigate]);

  useEffect(() => {
    fetchCompanyLanguages();
  }, []);

  useEffect(() => {
    const uuid = sessionStorage.getItem('uuid');
    if (uuid == null) {
      window.location.replace('/');
    }
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 'r') {
        event.preventDefault();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const handleMouseLeave = () => {
      document.body.style.cursor = 'not-allowed';
    };

    const handleMouseEnter = () => {
      document.body.style.cursor = 'auto';
    };
    window.addEventListener('mouseleave', handleMouseLeave);
    window.addEventListener('mouseenter', handleMouseEnter);
    return () => {
      window.removeEventListener('mouseleave', handleMouseLeave);
      window.removeEventListener('mouseenter', handleMouseEnter);
    };
  }, []);

  useEffect(() => {
    const hideLoader = () => {
      setImageLoading(false);
    };

    const showLoader = () => {
      setImageLoading(true);
    };
    window.addEventListener('load', hideLoader);
    window.addEventListener('beforeunload', showLoader);

    // Clean-up
    return () => {
      window.removeEventListener('load', hideLoader);
      window.removeEventListener('beforeunload', showLoader);
    };
  }, []);

  useEffect(() => {
    function handleVisibilityChange() {
      if (isMobileOrTablet()) {
        if (document.visibilityState === 'visible' || document.visibilityState === 'hidden' || document.beforeunload === 'visible' || document.beforeunload === 'hidden' || document.unload === 'visible' || document.unload === 'hidden') {
          window.location.replace('/');
        }
        if(document.visibilityState === 'visible') {
          window.location.reload();
        }
      }
    }
    function isMobileOrTablet() {
      const userAgent = navigator.userAgent.toLowerCase();
      return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    }
    document.addEventListener("visibilitychange", handleVisibilityChange);
    document.addEventListener("beforeunload", handleVisibilityChange);
    document.addEventListener("unload", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      document.removeEventListener("beforeunload", handleVisibilityChange);
      document.removeEventListener("unload", handleVisibilityChange);
    };
  }, []);

  const privacyStatus = sessionStorage.getItem('privacyStatus');

  const GoBack = () => {
    if(privacyStatus === 'null' || privacyStatus === null || privacyStatus === '' || privacyStatus === '0') {
      navigate('/');
    } else {
      navigate('/terms');
    }
    sessionStorage.removeItem('selectedLanguage');
  };

  useEffect(() => {
    const disableBackButton = (e) => {
      e.preventDefault();
      window.history.forward();
    };
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', disableBackButton);

    return () => {
      window.removeEventListener('popstate', disableBackButton);
    };
  }, []);

  // Check if bottom arrow should be shown
  const checkBottomArrow = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    if (scrollHeight <= clientHeight) {
      setShowBottomArrow(false);
    } else if (scrollTop + clientHeight >= scrollHeight - 50) {
      setShowBottomArrow(false);
    } else {
      setShowBottomArrow(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', checkBottomArrow);
    checkBottomArrow(); // Initial check
    return () => {
      window.removeEventListener('scroll', checkBottomArrow);
    };
  }, []);

  useEffect(() => {
    if (!loading) {
      checkBottomArrow();
    }
  }, [loading]);

  if (loading) {
    return (
      <div className='h-dvh flex items-center justify-center text-3xl'>
        {imageLoading && <img src={Loader} className='w-[70px]' alt="Loading..." />}
      </div>
    );
  }

  if (companyLanguages.length === 0) {
    return (
      <div className='h-dvh'>
        <Header showBackButton={false} showHomeButton={true} handleOpenModal={HeaderGoHome} /> 
        <div style={{backgroundImage: `url(${noAgent})`}} className='sm:w-[400px] w-[300px] bg-no-repeat m-auto noAdvisorImg'></div>
      </div>
    );
  }

  const isChangiClassPresent = document.body.classList.contains('changi');
  return (
    <>
       {/* <Helmet>
        <title>{company_name ? `${company_name} | Languages` : 'Voncierge | Languages'}</title>
      </Helmet> */}
      <div className='select-language pb-[20px]' style={{fontFamily}}>
        {/* <PusherFunction /> */}
        <div className={hideCategoryDiv ? "hidden" : "block"}>
          <Header showBackButton={true} showHomeButton={true} handleGoHome={HeaderGoHome} handleClickGoBack={GoBack} langCode={sessionStorage.getItem('selectedLanguage')} />
          <div className='language flex items-center xl:justify-center justify-start flex-col mt-[30px]'>
            <div className='flex items-start justify-center heading'>
              {isChangiClassPresent && 
                <span className='mr-3'>
                <svg width="78" height="78" viewBox="0 0 78 78" fill="none">
                <g clipPath="url(#clip0_5213_5462)">
                <path d="M14.8851 58.2543C13.6303 57.9203 12.4871 57.6883 11.395 57.3172C4.62856 55.0252 0.120695 48.9335 0.0602806 41.8025C-0.0140759 33.3678 -0.0233705 24.9331 0.0602806 16.5031C0.148579 7.32607 7.38905 0.125497 16.6976 0.0651827C31.5549 -0.0276082 46.4169 -0.0183291 61.2743 0.0651827C70.8198 0.116218 77.958 7.4235 77.9859 16.9717C78.0092 25.0584 78.0045 33.1451 77.9859 41.2319C77.9673 49.7779 72.3581 56.4867 63.9279 58.0131C62.8311 58.2126 61.6833 58.1894 60.5586 58.194C53.281 58.2079 46.0033 58.2172 38.7257 58.1847C37.7451 58.1847 37.0387 58.4399 36.3277 59.1544C30.4489 65.093 24.5282 70.9806 18.6308 76.896C17.9012 77.6291 17.1669 78.3157 16.033 77.8471C14.8944 77.3739 14.8805 76.3532 14.8805 75.3279C14.8898 70.0945 14.8805 64.861 14.8805 59.6276C14.8805 59.159 14.8805 58.6905 14.8805 58.2543H14.8851ZM18.5704 71.2451C18.7377 71.3564 18.905 71.4631 19.0723 71.5745C19.379 71.1569 19.6346 70.6929 19.9925 70.3311C24.8442 65.4503 29.7285 60.602 34.5571 55.698C35.4215 54.8211 36.337 54.487 37.5499 54.4917C45.3016 54.5288 53.0579 54.5195 60.8142 54.5056C68.6542 54.4917 74.2681 48.9428 74.3006 41.1298C74.3378 33.173 74.3285 25.2161 74.3006 17.2593C74.2727 9.32107 68.7564 3.75826 60.8235 3.73506C46.2682 3.69795 31.7129 3.69331 17.1576 3.73506C9.29908 3.75826 3.75487 9.34427 3.72699 17.1758C3.6991 25.2208 3.70375 33.2658 3.72699 41.3061C3.74558 48.5159 9.02489 54.0648 16.2282 54.4963C18.0639 54.6077 18.5658 55.1226 18.5704 57.0017C18.5797 61.284 18.5704 65.5663 18.5704 69.8439C18.5704 70.3079 18.5704 70.7718 18.5704 71.2358V71.2451Z" fill="#262626"/>
                <path d="M39.0465 9.95654C49.6609 9.99366 58.1933 18.5397 58.1887 29.1364C58.1887 39.7749 49.5633 48.3534 38.921 48.3024C28.3345 48.2513 19.7975 39.645 19.8439 29.0761C19.8904 18.4701 28.4739 9.92407 39.0465 9.96118V9.95654ZM34.9058 25.4573C34.9058 27.726 34.8733 29.9483 34.9476 32.1614C34.9569 32.4119 35.5657 32.8388 35.905 32.8527C37.9776 32.9176 40.0503 32.9084 42.123 32.8527C42.4483 32.8434 43.0432 32.4862 43.0478 32.2774C43.1175 30.0179 43.0896 27.7585 43.0896 25.4573H34.9011H34.9058ZM23.8174 25.4805V32.7599H31.0857V25.4805H23.8174ZM46.9469 32.8156H54.2245V25.4851H46.9469V32.8156ZM43.1408 14.1878C44.0795 16.6282 44.9579 18.9201 45.8548 21.1982C45.9384 21.4069 46.1801 21.6992 46.352 21.6992C48.3504 21.7363 50.3534 21.7224 52.6026 21.7224C50.3394 17.7881 47.2257 15.4683 43.1408 14.1878ZM34.8779 14.1971C30.7883 15.4498 27.6839 17.7974 25.4114 21.7363C27.6467 21.7363 29.5753 21.7595 31.504 21.7085C31.7456 21.7039 32.0988 21.3791 32.2011 21.1193C33.098 18.883 33.9484 16.6236 34.8825 14.1971H34.8779ZM43.1547 44.0711C47.3047 42.8138 50.3255 40.3919 52.6119 36.5643C50.4045 36.5643 48.4759 36.5458 46.5472 36.5875C46.3009 36.5922 45.9338 36.8891 45.8362 37.135C44.9439 39.362 44.1028 41.6029 43.1547 44.0711ZM34.8733 44.0572C33.9345 41.6075 33.084 39.3573 32.1871 37.1211C32.0895 36.8752 31.7177 36.5922 31.4621 36.5829C29.5382 36.5365 27.6095 36.5597 25.4207 36.5597C27.7071 40.4012 30.7372 42.8138 34.8686 44.0572H34.8733ZM42.2624 21.6528C41.1889 19.3052 40.2176 17.1757 39.2463 15.0508C39.0651 15.074 38.8839 15.0972 38.7026 15.1157C37.7639 17.2453 36.8251 19.3748 35.8213 21.6528H42.2671H42.2624ZM38.7166 43.1339C38.9071 43.1432 39.0976 43.1525 39.2882 43.1618C40.2502 41.0415 41.2075 38.9259 42.2392 36.6478H35.7934C36.8251 38.9351 37.7732 41.0322 38.7166 43.1293V43.1339Z" fill="#262626"/>
                </g>
                <defs>
                <clipPath id="clip0_5213_5462">
                <rect width="78" height="78" fill="white"/>
                </clipPath>
                </defs>
                </svg>  
              </span>              
              }
              <h2 className='sm:text-4xl text-xl mt-0 sm:mt-[10px] text-[#262626] font-bold lg:mb-[30px]'>
                {/* {t('headings.langHeading')} */} What language do you speak?
              </h2>
            </div>
            <div className='flex flex-wrap sm:gap-12 gap-3 w-[80%] max-w-full md:max-w-[800px] lg:max-w-[824px] xl:max-w-[1280px] sm:max-w-[100%] justify-center mt-[30px] sm:mt-[30px] mx-auto mb-8 sm:px-7 lg:px-0 languageName'>
              {companyLanguages.map((val, index) => {
                const key = `${val.locale}-${index}`;
                const className = activeId === val.locale
                  ? 'select-none sm:w-[200px] md:w-[250px] w-[46%] text-center selectedLanguage pointer-events-none h-[90px] rounded-[12px] px-[8px] lg:h-[186px] sm:h-[150px] sm:w-[44%] sm:text-2xl sm:rounded-[16px] border flex items-center cursor-pointer justify-center md:rounded-[30px] text-white uppercase lg:text-[32px] text-[14px]'
                  : 'select-none sm:w-[200px] md:w-[250px] w-[46%] text-center h-[90px] rounded-[12px] lg:h-[186px] sm:h-[150px] sm:text-2xl px-[8px] sm:rounded-[16px] border flex items-center cursor-pointer justify-center md:rounded-[30px] border-lightPink hover:text-white text-[#262626] uppercase lg:text-[32px] px-[8px] text-[14px] languageButton';

                const borderStyle = {
                  borderColor: primaryColor,
                };
                const handleMouseEnter = (e) => {
                  e.currentTarget.style.backgroundColor = primaryColor;
                  e.currentTarget.style.boxShadow  = '0px 0px 25px 0px #00000040';
                };
        
                const handleMouseLeave = (e) => {
                  e.currentTarget.style.backgroundColor = '';
                  e.currentTarget.style.boxShadow  = 'unset';

                };
                return (
                  <div
                    onClick={() => handleCheckboxClick(val.locale)}
                    onBlur={() => handleCheckboxClick(val.locale)}
                    className={className}
                    key={key}
                    style={borderStyle}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    {val.native_name}
                  </div>
                );
              })}
            </div>
          </div>
          {showBottomArrow && ( // Only show the bottom arrow if the condition is met
            <div className='fixed bottom-0 bottomArrow bg-white w-full flex justify-center items-center h-[68px] cursor-pointer'>
              <svg width="19" height="12" viewBox="0 0 19 12" fill="none">
                <path d="M9.445 7.11332C11.3895 5.15152 13.185 3.31371 15.0174 1.51428C15.3375 1.19986 15.7861 0.93563 16.2183 0.830823C16.9368 0.656637 17.6671 1.09505 17.9858 1.74013C18.3369 2.45311 18.2381 3.14986 17.6391 3.75655C15.938 5.47774 14.2207 7.18565 12.5108 8.89798C11.931 9.47959 11.3571 10.0686 10.7669 10.6384C9.98055 11.3956 9.0083 11.4074 8.23521 10.6398C5.9499 8.371 3.67491 6.09182 1.40434 3.80822C0.644539 3.04357 0.619458 2.03684 1.30402 1.32091C1.98858 0.604973 3.07444 0.60202 3.84752 1.36371C5.49549 2.986 7.1228 4.62748 8.75158 6.26896C8.97879 6.49776 9.16468 6.7679 9.44795 7.11332H9.445Z" fill="#262626"/>
              </svg>
            </div>
          )}
          <div className={onlineStatusShow ? "block fixed bg-themeColor text-white text-center py-2 bottom-0 w-full" : "hidden"}>
            We regret to inform you that there is no internet connection available at the moment. We apologise for any inconvenience this may cause.
          </div>
          {/* <Modal 
            BackToHome={HeaderGoHome} 
            closeModal={() => closeModal(false)} 
            isOpen={modalOpen} 
            popupLargeText={t('headings.langPopupLargeText')} 
            popupSmallText={t('headings.popupSmallerText', { countdown: countdown || '' })}
            btnDarkLabel={t('headings.continueBrowsing')} 
            btnLightLabel={t('headings.returnHome')} pageType="category">
          </Modal> */}
        </div>

        {apiError && <ApiCallingToaster />}
        {apiStatusError && <DynamicApiCallingToaster content={apiErrorMsg} />}
      </div>
    </>
  );
};

export default SelectLanguage;
