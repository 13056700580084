
import React from 'react';
import './App.css';
import './CompanyCustom.css'
import './font.css';
import { Routes, Route } from 'react-router-dom';

import Screensaver from './Pages/Screensaver'
import SelectLanguages from './Pages/SelectLanguage'
import SelectCategory from './Pages/SelectCategory'
import Terms from './Pages/Terms'
import { I18nextProvider } from 'react-i18next'; // Add this import
import i18n from './Pages/i18n'; // Import i18n configuration


const App = () => {

  return (
    <I18nextProvider i18n={i18n}>
      <div className='homePage'>
        <Routes>
          <Route path="/" exact element={<Screensaver />} />
          <Route path="/languages" exact element={<SelectLanguages />} />
          <Route path="/terms" exact element={<Terms />} />
          <Route path="/:langCode/categories" element={<SelectCategory />} />
        </Routes>
      </div>
  </I18nextProvider>
  );
};

export default App;
