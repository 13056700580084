import React, { useState, useEffect } from 'react'
import Header from '../Components/Headers/Header'
import { useNavigate } from 'react-router-dom'
import Modal from '../Components/Modal';
// import { useTranslation } from 'react-i18next'; 
import Loader from '../images/loader.gif'
import DynamicApiCallingToaster from '../Components/Toaster/DynamicApiCallingToaster';
import { getHeaders } from '../utils/apiUtils';
// import { Helmet } from 'react-helmet';


var noAgent;
var apiBodyData;

const SelectCategory = () => {
  const fontFamily  = sessionStorage.getItem('fetchedFontFamily')
  const company_name  = sessionStorage.getItem('company_name')
  const primaryColor  = sessionStorage.getItem('primaryColor')

  const [onlineStatusShow, setOnlineStatusShow] = useState(false); 
  const [onlineStatus, setOnlineStatus] = useState(navigator.onLine); 
  const [apiError, setApiError] = useState(false)
  const [apiErrorMsg, setApiErrorMsg] = useState(false)
  const [hideCategoryDiv, sethideCategoryDiv] = useState(false)
  const [showBottomArrow, setShowBottomArrow] = useState(false)
  // const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [companyCategory, setCompanyCategory] = useState([0]);
  const [catactiveId, setCatActiveId] = useState(() => {
    return parseInt(sessionStorage.getItem('selectedCat') || 0);
  });
  useEffect(() => {
    noAgent = sessionStorage.getItem('no_agent')
    const handleOnlineStatusChange = () => {
      setOnlineStatus(navigator.onLine);
    };

    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);

    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, []);
  useEffect(() => {
    if (company_name) {
      const cleanedCompanyName = company_name.replace(/\s+/g, '');
      document.body.classList.add(cleanedCompanyName);
    }
  }, [company_name])
  useEffect(() => {
    // Check if the internet connection is lost
    if (!onlineStatus) {
      setOnlineStatusShow(true)
    }
    else {
      // window.location.reload();
      setOnlineStatusShow(false)
    }
  }, [onlineStatus]);
  useEffect(() => {
    const disableBackButton = (e) => {
      e.preventDefault();
      window.history.forward();
    };

    const handlePopState = () => {
      sessionStorage.removeItem('selectedCat');
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', disableBackButton);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', disableBackButton);
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const navigate = useNavigate(); 
  const fetchLogoutApi = async () => {
    try {
      let tempID = sessionStorage.getItem('temp_CallId');
    
    // Example of conditionally changing `tempID`
    if (window.location.pathname === '/feedback') {
      tempID = sessionStorage.getItem('call_id');
    }
      const logoutUrl = `${process.env.REACT_APP_BASE_URL_API}passenger/logout`;

      const logoutResponse = await fetch(logoutUrl, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({ 'call_id': tempID }),
      });

      if (!logoutResponse.ok) {
        const errorData = await logoutResponse.json();
        let errorMessage = errorData.error.message;
        setApiError(true);
        setApiErrorMsg(errorMessage);
        window.location.replace('/');
        return;
      }
      const keepItemKey = 'textColorVal';
      const keepItemValue = sessionStorage.getItem(keepItemKey)

      console.log('keepItemKey', keepItemValue)
      sessionStorage.clear();
      if (keepItemValue !== null) {
        sessionStorage.setItem(keepItemKey, keepItemValue);
      }
      window.location.replace('/');
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

//   const fetchData = async () => {
//     try {
//         // setIsLoading(true)
//         const selLang = sessionStorage.getItem('selectedLanguage');
//         let selCat = sessionStorage.getItem('selectedCat');
//         if (sessionStorage.getItem('selectedSubCat')) {
//             selCat = sessionStorage.getItem('selectedSubCat');
//         }
//         const url = `${process.env.REACT_APP_BASE_URL_API}request-call`;
//         const firebase_token = sessionStorage.getItem('firebase_token');
//         apiBodyData = {
//             category_id: selCat
//         };
//         if (firebase_token != null) {
//             apiBodyData = {
//                 category_id: selCat,
//                 web_push_token: firebase_token
//             };
//         }
//         const fetchResponse = await fetch(url, {
//             method: 'POST',
//             headers: getHeaders(),
//             body: JSON.stringify(apiBodyData), 
//         });
//         // console.log('Requesting API:', apiBodyData);
//         if (!fetchResponse.ok) {
//             const errorData = await fetchResponse.json(); 
//             let errorMessage = errorData.error.message;
//             setApiError(true);
//             if (fetchResponse.status === 420) {
//                 setApiErrorMsg(errorMessage);
//                 return;
//             } else {
//                 setApiErrorMsg(errorMessage);
//                 setTimeout(() => {
//                     fetchLogoutApi(); // Logout API
//                     // window.location.replace('/');
//                 }, 1000);
//                 return;
//             }
//         }
//         const resData = await fetchResponse.json();
//         sessionStorage.setItem('temp_CallId', resData.data.call_id);
//         const queueNo = resData.data.queue_no;
//         const waitingImg = resData.data.company.queue_waiting_image;
//         sessionStorage.setItem('queueNo', queueNo);
//         sessionStorage.setItem('waitingImg', waitingImg);
//         sessionStorage.setItem('avg_time', resData.data.avg_queue_time);

//         navigate(`/${selLang}/category/${selCat}/queue`);

//     } catch (error) {
//         setApiError(true);
//         document.body.style.overflow = 'hidden';
//         setTimeout(() => {
//             window.location.replace('/');
//         }, 1000);
//     }
//     finally {
//         // setIsLoading(false)
//     }
// };
  const handleRedirectClick = async (id) => {
    sessionStorage.setItem('selectedCat', id);
    const getSubCat = JSON.parse(sessionStorage.getItem('categroyJSON'));
    
    for (const category of getSubCat) {
      if (category.category_id === id) {
        setCatActiveId(id);
        
        if (category.sub_category && category.sub_category.length > 0) {
            navigate(`/${sessionStorage.getItem('selectedLanguage')}/category/${id}/subCategories`);
        } 
        else {
          const actionType = category.action_type;
          const actionFile = category.action_file;
          if (actionType != null) {
              if (actionType == '1' || actionType == '2' || actionType == '3' || actionType == '4') {
                window.open(actionFile);
              }
              else if (actionType == '5') {
                // navigate('/permissions')
                // try {
                //   const micPermission = await navigator.permissions.query({ name: 'microphone' });
                //   const locationPermission = await navigator.permissions.query({ name: 'geolocation' });
                  
                //   // If either microphone or location is denied or prompt, navigate to permissions page
                //   if (micPermission.state !== 'granted' || locationPermission.state !== 'granted') {
                //     navigate('/permissions');
                //   } else {
                //     fetchData();
                //   }
                // } catch (error) {
                //   console.error('Permission check failed:', error);
                //   navigate('/permissions');
                // }
              }
            }
          }
        return;
      }
    }
  };
  useEffect(() => {
    const uuid = sessionStorage.getItem('uuid');
        if (uuid == null) {
          window.location.replace('/')
        }
}, [])
  const GoHome = () => {
    navigate('/')
  }
  useEffect (() => {
    const fetchCategoryData = async () => {
      try {
        sethideCategoryDiv(false)
          const categoryUrl = `${process.env.REACT_APP_BASE_URL_API}company-categories?limit=`;
          const categoryResponse = await fetch(categoryUrl, {
            method: 'GET',
            headers: getHeaders(),
          });
          if (!categoryResponse.ok) {
              const errorData = await categoryResponse.json(); 
              let errorMessage = errorData.error.message;
              setApiError(true);
              sethideCategoryDiv(true) 
              if (categoryResponse.status == 420) {
                setApiErrorMsg(errorMessage);
                return;
              }
              else {
                setApiErrorMsg(errorMessage);
                setTimeout(() => {
                  fetchLogoutApi();
                }, 1000);
                return;
              }
            }
          const catResponseData = await categoryResponse.json();
          sessionStorage.setItem('categroyJSON', JSON.stringify(catResponseData.data));
          setCompanyCategory(catResponseData.data);
      } 
      catch (error) {

      } 
      finally {
        setLoading(false);
        checkBottomArrow();
      }
    }
    fetchCategoryData();
  }, [])

  const GoBack = () => {
    navigate('/languages')
    sessionStorage.removeItem('selectedLanguage');
    sessionStorage.removeItem('selectedCat')
  }
   useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 'r') {
        event.preventDefault(); 
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const hideInactivePopup = parseInt(sessionStorage.getItem('inactivity_popup_dismiss_time')) * 1000;
  const [countdown, setCountdown] = useState(hideInactivePopup / 1000);
    
    const closeModal = (redirect) => {
      setModalOpen(false);
      if (redirect) {
        window.location.replace('/');
      } else {
        window.location.reload();
        setCountdown(0);
      }
    };
    useEffect(() => {
      let timer;
      if (modalOpen && countdown > 0) {
        timer = setInterval(() => {
          setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);
      }
  
      return () => {
        clearInterval(timer);
      };
    }, [modalOpen, countdown]);
  
    useEffect(() => {
      if (countdown === 0) {
        closeModal()
      }
    }, [countdown, closeModal]);

    useEffect(() => {
      const showInactivePopup = parseInt(sessionStorage.getItem('inactivity_popup_show_time')) * 1000;
    
      let inactivityTimer;
      let closeTimer;
  
      const resetTimers = () => {
        clearTimeout(inactivityTimer);
        clearTimeout(closeTimer);
        inactivityTimer = setTimeout(() => {
          setModalOpen(true);
          closeTimer = setTimeout(() => {
            setModalOpen(false);
            setTimeout(() => {
              navigate('/');
            }, 500)
          }, hideInactivePopup);
        }, showInactivePopup);
      };
  
      const clearTimers = () => {
        clearTimeout(inactivityTimer);
        clearTimeout(closeTimer);
      };
  
      const handleActivity = () => {
        resetTimers();
      };
  
      // Initial setup
      resetTimers();
  
      // Event listeners
      window.addEventListener('mousemove', handleActivity);
      window.addEventListener('keypress', handleActivity);
      window.addEventListener('click', handleActivity);
      window.addEventListener('scroll', handleActivity);
      window.addEventListener('touchstart', handleActivity);
      // Clean-up
      return () => {
        window.removeEventListener('mousemove', handleActivity);
        window.removeEventListener('keypress', handleActivity);
        window.removeEventListener('click', handleActivity);
        window.removeEventListener('scroll', handleActivity);
        window.removeEventListener('touchstart', handleActivity);
        clearTimeout(inactivityTimer);
        clearTimeout(closeTimer);
      };
    }, []);
  useEffect(() => {
    function handleVisibilityChange() {
      if (isMobileOrTablet()) {
        if (document.visibilityState === 'visible' || document.visibilityState === 'hidden') {
          window.location.replace('/');
        }
        if (document.visibilityState === 'visible') {
          window.location.reload();
        }
      }
    }
  
    function isMobileOrTablet() {
      const userAgent = navigator.userAgent.toLowerCase();
      return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    }
  
    document.addEventListener("visibilitychange", handleVisibilityChange);
  
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  useEffect(() => {
    checkBottomArrow();
    window.addEventListener('resize', checkBottomArrow);
    window.addEventListener('scroll', checkBottomArrow);

    return () => {
      window.removeEventListener('resize', checkBottomArrow);
      window.removeEventListener('scroll', checkBottomArrow);
    };
  }, [companyCategory]);

  const checkBottomArrow = () => {
    const bottomArrowCondition = document.documentElement.scrollHeight > window.innerHeight;
    setShowBottomArrow(bottomArrowCondition);
  };
  const onHandleArrowClick = ()=> {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth'
    });
  }
  if (loading) {
    return <div className='h-dvh flex items-center justify-center text-3xl text-OyesterGrey'>
       <img src={Loader} className='w-[70px]' alt="Loading..." />
    </div>
  }
  if (companyCategory.length === 0) {
    setTimeout(() => {window.location.replace('/languages')}, 500)
    
   return <div className='h-dvh'> <Header showBackButton={true} showHomeButton={true} handleGoHome={GoHome} handleClickGoBack={GoBack} /> 
   <div className='sm:w-[400px] w-[300px] bg-no-repeat m-auto noAdvisorImg text-[40px] text-OyesterGrey flex items-center justify-center'>No category Available</div></div>;
  }
  return (
    <>
     {/* <Helmet>
        <title>{company_name ? `${company_name} | Categories` : 'Voncierge | Categories'}</title>
      </Helmet> */}
      <div className='screensaver categoryPage pb-[20px]' style={{fontFamily}}>
        <div className={hideCategoryDiv ? "hidden" : "block"}>
          <Header showBackButton={true} showHomeButton={true} handleGoHome={GoHome} handleClickGoBack={GoBack} />
          <div className='flex items-center justify-center mt-[20px] sm:mt-12 mx-[20px] text-center heading'>
            {/* <span className='sm:text-4xl text-xl text-[#262626] font-bold'>{t('headings.categoryHeading')}</span> */}
            <span className='sm:text-4xl text-xl text-[#262626] font-bold'>How may we assist you today?</span>

          </div>
          <div className='flex justify-center flex-wrap gap-3 sm:gap-8 lg:max-w-[824px] xl:max-w-[1280px] mt-[20px] sm:mt-[70px] md:max-w-auto m-auto mb-8 categoryName'>
            {companyCategory.map((catVal) => {
              const isSelected = catactiveId === catVal.category_id;
              const borderColor = { borderColor: primaryColor };

              const handleMouseEnter = (e) => {
                const bodyClassList = document.body.classList;
                if (bodyClassList.contains('changi')) {
                  e.currentTarget.style.backgroundColor = '#d03c92';  // Apply red background color
                  e.currentTarget.querySelector('p').style.color = 'white';  // Change text color to white
                } else {
                  e.currentTarget.style.backgroundColor = primaryColor;
                  e.currentTarget.querySelector('p').style.color = '#fff';  // Default text color
                }
                e.currentTarget.style.boxShadow = '0px 0px 25px 0px #00000040';
              };

              const handleMouseLeave = (e) => {
                const bodyClassList = document.body.classList;
                e.currentTarget.style.backgroundColor = '';
                if (bodyClassList.contains('changi')) {
                  e.currentTarget.querySelector('p').style.color = isSelected ? '#7d6a55' : '#7d6a55';
                 }
                 else {
                  e.currentTarget.querySelector('p').style.color = isSelected ? '#7d6a55' : '#262626';
                 }
                  // Reset text color under p tag
                e.currentTarget.style.boxShadow = 'unset';
              };
              return (
                <div
                  key={catVal.category_id}
                  onClick={() => handleRedirectClick(catVal.category_id)}
                  onBlur={() => handleRedirectClick(catVal.category_id)}
                  data-actiontype={catVal.action_type}
                  data-actionfile={catVal.action_file}
                  className={isSelected
                    ? "select-none selectedCat pointer-events-none sm:w-[40%] xl:w-[30%] w-[40%] h-auto sm:min-h-[265px] border sm:rounded-[34px] rounded-[12px] cursor-pointer"
                    : "select-none sm:w-[40%] xl:w-[30%] h-auto w-[40%] sm:min-h-[265px] border group lg:hover:text-white sm:rounded-[34px] rounded-[12px] cursor-pointer languageButton"}
                  style={borderColor}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <div className='w-full h-full py-5 sm:py-7 md:py-10 flex items-center justify-center flex-col sm:px-7 text-center break-all'>
                    <div
                      style={{ backgroundImage: `url(${catVal.icon})` }}
                      className='sm:w-[96px] sm:h-[96px] w-[40px] h-[40px] select-none bg-contain rounded-full categoryIcon'
                    ></div>
                    <p className={`${isSelected ? "text-white" : "text-[#262626] group-hover:text-white"} sm:text-xl xl:text-2xl font-normal mt-[8px] px-[8px] sm:mt-[10px] text-[14px] md:text-xl leading-[normal]`} style={{ wordBreak: "break-word" }}>
                      {catVal.title}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {showBottomArrow && 
            <div className='fixed bottom-0 bottomArrow bg-white w-full flex justify-center items-center h-[68px] cursor-pointer' onClick={onHandleArrowClick}>
            <svg width="19" height="12" viewBox="0 0 19 12" fill="none">
              <path d="M9.445 7.11332C11.3895 5.15152 13.185 3.31371 15.0174 1.51428C15.3375 1.19986 15.7861 0.93563 16.2183 0.830823C16.9368 0.656637 17.6671 1.09505 17.9858 1.74013C18.3369 2.45311 18.2381 3.14986 17.6391 3.75655C15.938 5.47774 14.2207 7.18565 12.5108 8.89798C11.931 9.47959 11.3571 10.0686 10.7669 10.6384C9.98055 11.3956 9.0083 11.4074 8.23521 10.6398C5.9499 8.371 3.67491 6.09182 1.40434 3.80822C0.644539 3.04357 0.619458 2.03684 1.30402 1.32091C1.98858 0.604973 3.07444 0.60202 3.84752 1.36371C5.49549 2.986 7.1228 4.62748 8.75158 6.26896C8.97879 6.49776 9.16468 6.7679 9.44795 7.11332H9.445Z" fill="#262626"/>
            </svg>
          </div>
          }
        {apiError && <DynamicApiCallingToaster content={apiErrorMsg} />}
        {/* {logoutApiError && <DynamicApiCallingToaster content={logoutApiErrorMsg} />} */}
        <div className={onlineStatusShow ? "block fixed bg-themeColor text-white text-center py-2 bottom-0 w-full" : "hidden"}>
          {/* {t('headings.no_internet_popup_label')} */}
          We regret to inform you that there is no internet connection available at the moment. We apologise for any inconvenience this may cause.
        </div>
        
        {/* <Modal 
          BackToHome={GoHome} 
          closeModal={() => closeModal(false)} 
          isOpen={modalOpen} 
          pageType="category" 
          popupLargeText={t('headings.langPopupLargeText')} 
          popupSmallText={`Continue browsing or be redirected to the home page in ${countdown} seconds.`} 
          btnDarkLabel={t('headings.continueBrowsing')} 
          btnLightLabel={t('headings.returnHome')}>
        </Modal> */}
      </div>
    </>
  )
}

export default SelectCategory