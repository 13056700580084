  import React, { useState, useEffect } from 'react';
  import LogoLight from '../LogoLight';
  import { useNavigate } from 'react-router-dom';
  // import { useTranslation } from 'react-i18next';

  const Header = ({ showBackButton, showHomeButton, handleClickGoBack, langCode, handleGoHome }) => { 
    const headerColor = sessionStorage.getItem('headerColor')
    // const { t } = useTranslation();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1025);
   
    const nav = useNavigate();
    const handleGoBack = () => {
      handleClickGoBack();
    };

    const handleNavigateToHome = () => {
      if (handleGoHome) {
        handleGoHome();
      } else {
        nav(`/${langCode ? langCode : ''}`);
      }
    };
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 1025);
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
      <>
        {isMobile ? (
          <div className='w-full h-[60px] sm:h-[100px] relative header' style={{backgroundColor : `${headerColor}`}}>
          {showBackButton && (
            <div className='cursor-pointer absolute left-[1rem] sm:left-[2rem] xl:left-20 headerBtn top-[50%]' onClick={handleGoBack}>            
                
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                  <path d="M7.05654 5.819C0.919143 11.1895 -0.188999 25.5323 0.0241046 32.0324C-0.189003 39.5981 0.983067 54.4097 8.0155 58.8852C15.0479 63.3606 26.449 63.8934 32.3094 64C38.7025 64 46.0546 64 55.6443 59.2049C62.3571 54.4097 63.6357 45.1391 63.9553 32.0324C64.275 18.9257 62.9964 9.9748 56.2836 4.85999C50.9443 0.791695 37.9566 -0.148276 32.3094 0.064834C26.449 -0.254842 13.1939 0.448445 7.05654 5.819Z" fill="white"/>
                  <path d="M34.853 51.1109L17.8891 34.147C17.3198 33.5776 17 32.8053 17 32C17 31.1947 17.3198 30.4224 17.8891 29.853L34.853 12.8891C35.2777 12.4646 35.8187 12.1754 36.4077 12.0583C36.9967 11.9412 37.6072 12.0013 38.1621 12.2311C38.7169 12.4609 39.1911 12.8501 39.5248 13.3494C39.8585 13.8486 40.0367 14.4357 40.0368 15.0362V48.9638C40.0367 49.5643 39.8585 50.1514 39.5248 50.6506C39.1911 51.1499 38.7169 51.5391 38.1621 51.7689C37.6072 51.9987 36.9967 52.0588 36.4077 51.9417C35.8187 51.8246 35.2777 51.5354 34.853 51.1109Z" fill={headerColor} />
                </svg>

            </div>
          )}
          <div className='h-full logoSection'>
            <LogoLight />
          </div>
          {showHomeButton && (
            <div className='cursor-pointer absolute sm:right-15 right-[14px] sm:right-[2rem] w-auto xl:right-20 headerBtn homeButton top-[50%]' onClick={handleNavigateToHome}>            
                
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                  <path d="M7.05654 5.819C0.919143 11.1895 -0.188999 25.5323 0.0241046 32.0324C-0.189003 39.5981 0.983067 54.4097 8.0155 58.8852C15.0479 63.3606 26.449 63.8934 32.3094 64C38.7025 64 46.0546 64 55.6443 59.2049C62.3571 54.4097 63.6357 45.1391 63.9553 32.0324C64.275 18.9257 62.9964 9.9748 56.2836 4.85999C50.9443 0.791695 37.9566 -0.148276 32.3094 0.064834C26.449 -0.254842 13.1939 0.448445 7.05654 5.819Z" fill="white"/>
                  <path fillRule="evenodd" clipRule="evenodd" d="M47 51.9751H40.3334V41.9751C40.2984 37.2763 36.3758 33.5018 31.7973 33.6123C27.3671 33.7192 23.6978 37.4304 23.6666 41.9751V51.9751H17C14.2386 51.9751 12 49.7365 12 46.9751V31.7985C11.9998 30.918 12.3479 30.0731 12.9684 29.4485L27.1067 14.1417C27.2254 14.0133 27.3491 13.8896 27.4776 13.7709C30.1815 11.2718 34.3993 11.4378 36.8984 14.1417L51.0616 29.4535C51.6639 30.0756 52.0004 30.9076 52 31.7735V46.9751C52 49.7365 49.7614 51.9751 47 51.9751ZM27 41.9751C27 39.2137 29.2386 36.9751 32 36.9751C34.7614 36.9751 37 39.2137 37 41.9751V51.9751H27V41.9751Z" fill={headerColor} />
                </svg>

            </div>
          )}
        </div>
        ) : (
          <div className='w-full h-[60px] sm:h-[100px] relative header desktop' style={{backgroundColor : `${headerColor}`}}>
            {showBackButton && (
              <div className='cursor-pointer absolute left-4 sm:left-15 xl:left-20 headerBtn top-[50%] transition-all btnEffect bg-[#FFFFFF] rounded-[67px] flex items-center justify-center gap-[10px]' onClick={handleGoBack}>
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                    <g clipPath="url(#clip0_6450_3858)">
                    <path d="M20.0209 40C8.96813 39.9117 0.0170743 31.2397 2.46483e-05 20.0101C-0.017025 8.89046 8.81314 -0.017016 20.0473 2.44084e-05C31.111 0.0170648 39.9504 8.79751 40 19.8986C40.0496 31.0616 31.1993 39.8792 20.0225 40H20.0209Z" fill={headerColor} />
                    <path d="M17.2664 19.6902C19.3263 21.732 21.256 23.6172 23.1454 25.5413C23.4756 25.8774 23.753 26.3484 23.8631 26.8022C24.046 27.5567 23.5856 28.3235 22.9083 28.6581C22.1596 29.0268 21.4281 28.923 20.791 28.2941C18.9838 26.5079 17.1904 24.7047 15.3925 22.9093C14.7818 22.3005 14.1634 21.6979 13.5651 21.0782C12.7699 20.2525 12.7575 19.2317 13.5635 18.4199C15.9458 16.0203 18.339 13.6316 20.7368 11.2475C21.5397 10.4497 22.5967 10.4233 23.3485 11.1421C24.1002 11.8609 24.1033 13.0011 23.3035 13.8128C21.6001 15.5432 19.8765 17.2519 18.153 18.9621C17.9127 19.2007 17.6291 19.3959 17.2664 19.6933V19.6902Z" fill="white"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_6450_3858">
                    <rect width="40" height="40" fill="white"/>
                    </clipPath>
                    </defs>
                  </svg>
                  {/* <span className='text-[#262626] text-[24px] font-bold'>{t('headings.back')}</span> */}
                  <span className='text-[#262626] text-[24px] font-bold'>Back</span>

              </div>
            )}
            <div className='h-full logoSection'>
              <LogoLight />
            </div>
            {showHomeButton && (
              <div className='cursor-pointer absolute sm:right-15 right-4 xl:right-20 headerBtn top-[50%] transition-all btnEffect bg-[#FFFFFF] rounded-[67px] flex items-center justify-center gap-[10px]' onClick={handleNavigateToHome}>
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.0256 5.22675e-07C31.086 0.00249499 40.0075 8.97384 40 20.0879C39.9925 31.0923 30.9962 40.0087 19.9121 40C8.88405 39.9913 -0.0124592 30.9875 1.3098e-05 19.8485C0.0124854 8.92395 9.01875 -0.00249394 20.0256 5.22675e-07ZM10.7349 20.6143C10.7349 20.9248 10.7349 21.1506 10.7349 21.3751C10.7349 23.8546 10.7325 26.3328 10.7362 28.8123C10.7374 29.9635 11.3436 30.5859 12.4973 30.6021C13.5599 30.6171 14.6226 30.6058 15.6852 30.6058C16.0731 30.6058 16.461 30.6058 16.8975 30.6058C16.8975 30.2903 16.8975 30.0633 16.8975 29.8363C16.8975 28.3159 16.8751 26.7943 16.9038 25.2739C16.9387 23.4168 18.2246 22.1197 19.9832 22.1147C21.7493 22.1097 23.0376 23.3869 23.0975 25.239C23.1274 26.1545 23.1038 27.0724 23.1038 27.9891C23.1038 28.8373 23.1038 29.6866 23.1038 30.5722C24.7513 30.5722 26.3303 30.627 27.9019 30.551C28.7799 30.5086 29.2601 29.8288 29.2626 28.9096C29.2676 26.3889 29.2651 23.8683 29.2651 21.3476C29.2651 21.1244 29.2651 20.9011 29.2651 20.6542C29.4958 20.6392 29.6567 20.623 29.8189 20.6193C30.8229 20.5968 31.3629 20.26 31.6248 19.4893C31.878 18.7447 31.6797 18.2196 30.8852 17.5536C27.9829 15.119 25.0794 12.6844 22.1746 10.251C20.8076 9.10605 19.1762 9.11602 17.803 10.2697C16.6244 11.2613 15.4408 12.2478 14.2609 13.2369C12.4748 14.7361 10.6838 16.229 8.9065 17.7381C8.38017 18.1847 8.11576 18.7409 8.3652 19.4556C8.61215 20.1653 9.11728 20.5619 9.8968 20.6118C10.145 20.6267 10.3945 20.6143 10.7349 20.6143Z" fill={headerColor} />
                  <path d="M10.7349 20.6143C10.3944 20.6143 10.1449 20.628 9.89671 20.6118C9.11719 20.5631 8.61206 20.1665 8.36511 19.4556C8.11566 18.7409 8.38132 18.1847 8.90641 17.7382C10.6837 16.229 12.4747 14.7348 14.2608 13.2369C15.4407 12.2466 16.6243 11.26 17.8029 10.2697C19.1761 9.11604 20.8075 9.10606 22.1745 10.251C25.0793 12.6844 27.9828 15.119 30.8851 17.5536C31.6796 18.2196 31.8779 18.7447 31.6248 19.4893C31.3628 20.2613 30.824 20.5968 29.8188 20.6193C29.6566 20.623 29.4945 20.6392 29.265 20.6542C29.265 20.9024 29.265 21.1244 29.265 21.3476C29.265 23.8683 29.2687 26.389 29.2625 28.9096C29.26 29.8276 28.7798 30.5073 27.9018 30.551C26.329 30.6283 24.75 30.5722 23.1037 30.5722C23.1037 29.6866 23.1037 28.8385 23.1037 27.9892C23.1037 27.0724 23.1274 26.1545 23.0974 25.239C23.0376 23.3869 21.7504 22.1097 19.9831 22.1147C18.2245 22.1197 16.9386 23.4168 16.9037 25.2739C16.875 26.7943 16.8974 28.3159 16.8974 29.8363C16.8974 30.0633 16.8974 30.2891 16.8974 30.6059C16.4609 30.6059 16.073 30.6059 15.6851 30.6059C14.6225 30.6059 13.5598 30.6171 12.4972 30.6021C11.3435 30.5859 10.7373 29.9648 10.7361 28.8123C10.7324 26.3328 10.7349 23.8546 10.7349 21.3751C10.7349 21.1493 10.7349 20.9248 10.7349 20.6143Z" fill="white"/>
                  </svg>
                  <span className='text-[#262626] text-[24px] font-bold'>Home</span>
                  {/* <span className='text-[#262626] text-[24px] font-bold'>{t('headings.home')}</span> */}
              </div>
            )}
          </div>
        )
        }
      </>
    );
  };

  export default Header;
