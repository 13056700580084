import React, { useEffect, useState } from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { getHeaders } from '../utils/apiUtils';
import { useNavigate } from 'react-router-dom';
import DynamicApiCallingToaster from '../Components/Toaster/DynamicApiCallingToaster';
import ApiCallingToaster from '../Components/Toaster/ApiCallingToaster';
// import Cookies from 'js-cookie';
// import ButtonWhite from '../Components/Button/ButtonWhite';

const ReCaptcha = () => {
  const [token, setToken] = useState('');
  const [error, setError] = useState('');
  const [defaultApiError, setDefaultApiError] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState('');
  const [showCaptcha, setShowCaptcha] = useState(true);
  const [isVerified, setIsVerified] = useState(false); 
  const nav = useNavigate();
  const privacyStatus = sessionStorage.getItem('privacyStatus');

  // useEffect(() => {
  //   const cookieValue = Cookies.get('captchaVerifiedCookie');
  //   if (cookieValue == 'true') {
  //     setShowCaptcha(false);
  //     setIsVerified(true);  
  //     sessionStorage.setItem('capatchaVerified', 1);
  //   }
  //   // console.log('Cookie Value:', cookieValue);
  // }, []);

  const verifyCaptcha = async (token) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL_API}captcha-verifiy`;
      const response = await fetch(url, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({ 
          captcha_token: token
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        let errorMessage = errorData.error.message;
        if (response.status == 500) { 
          setApiErrorMsg(errorMessage);
        }
        setApiError(true);
        setApiErrorMsg(errorData.error.message);
        return;
      }

      // Cookies.set('captchaVerifiedCookie', 'true', { expires: 1, sameSite: 'None', secure: true });
      // console.log('Captcha Verified Cookie Set:', Cookies.get('captchaVerifiedCookie'));
      
      setIsVerified(true); 
      setShowCaptcha(false);  
      sessionStorage.setItem('capatchaVerified', 1);

      if (!privacyStatus || privacyStatus === 'null' || privacyStatus === '' || privacyStatus === '0') {
        nav('/languages');
      } 
      else {
        nav('/terms');
      }
    } catch (error) {
      setDefaultApiError(true);  // Handle API error
    }
  };

  const onVerify = (token) => {
    setToken(token);
    verifyCaptcha(token);
  };

  const onError = (err) => {
    setError('Captcha verification failed, please try again.');
  };

  const onExpire = () => {
    setToken('');
    setError('hCaptcha token expired, please complete the captcha again.');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isVerified) {
      setError('Please complete the hCaptcha.');
      return;
    }

    // Navigate after captcha is verified
    if (!privacyStatus || privacyStatus === 'null' || privacyStatus === '' || privacyStatus === '0') {
      nav('/languages');
    } else {
      nav('/terms');
    }
  };

  return (
    <div className='flex justify-center lg:justify-start'>
      <form onClick={handleSubmit}>
        {/* {showCaptcha && (handleSubmit */}
          <HCaptcha
            sitekey={process.env.REACT_APP_CAPTCHA_KEY}  // Use your actual site key
            onVerify={onVerify}
            onError={onError}
            onExpire={onExpire}
          />
        {/* )} */}
        {/* {isVerified && 
        <ButtonWhite btnLabel="Continue" btnClick={handleSubmit} disabled={!isVerified} /> } */}
        {error && <p style={{ color: 'red' }}>{error}</p>}  {/* Show error if exists */}
      </form>
      {defaultApiError && <ApiCallingToaster />}
      {apiError && <DynamicApiCallingToaster content={apiErrorMsg} />}
    </div>
  );
};

export default ReCaptcha;
